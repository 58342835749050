import * as Turbo from '@hotwired/turbo'
import Alpine from 'alpinejs'
import './application.css'

Turbo.setFormMode("optin")
//Turbo.start()

window.Alpine = Alpine
Alpine.start();

window.csrfToken = document.getElementsByName('csrf-token')[0].content
